import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Seeberg: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Seeberg,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Seeberg"
          d="M452,324c-11.1,20.3-7.4,16.1-25.5,30.6c-0.6-0.8-45.9-49.9-46.5-50.6l22-34h10 C427.2,304.4,424.7,299.1,452,324z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 394.3332 299.3978)"
        >
          <tspan x="0" y="0">
            See-
          </tspan>
          <tspan x="0" y="14.4">
            berg
          </tspan>
        </text>
      </g>
    </>
  );
});
