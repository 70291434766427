import { useExpansionMapContext } from './index';
import { Districts } from '../../constants/districts';
import React, { useEffect, useState } from 'react';

export interface useExpansionMapHelper {
  district: Districts;
  ref: React.RefObject<SVGTextElement>;
}

export const useExpansionMapHelper = ({
  district,
  ref,
}: useExpansionMapHelper) => {
  const {
    expansionState,
    handleSpotted,
    handleSelection,
    resetDistrict,
    selectedDistrict,
  } = useExpansionMapContext();
  const [expansionStatusClass, setExpansionStatusClass] = useState<string>();
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = () => {
    !isSelected ? handleSelection({ ref, district }) : resetDistrict();
  };

  const handleHover = () => {
    handleSpotted(district);
  };

  useEffect(() => {
    const expansionValue = expansionState?.[district] || 100;
    const expansionClass =
      expansionValue <= 50
        ? 'expansionStatus50'
        : expansionValue <= 70
        ? 'expansionStatus70'
        : 'expansionStatus100';

    setExpansionStatusClass(expansionClass);
  }, []);

  useEffect(() => {
    setIsSelected(selectedDistrict === district);
  }, [district, selectedDistrict]);

  return {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  };
};
